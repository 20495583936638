<template>
  <div class="hello">
      <!--<li @click="openModal">koe</li>-->
        <table class="table table-striped">
            <tr class="airport" v-for="(airport, idx) in ActiveAirports" :key="idx">
                <td class="icao">{{ airport.icao }}</td>
                <td class="rwy dropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                    <span class="rwy-option" v-for="(rwy, id, rwyIdx) in airport.runways" :key="rwyIdx">
                        <span v-if="rwy.active">
                            <div class="rwy-id" style="padding-right: 5px">{{ id }}</div>
                            <div class="rwy-components">
                                <div :class="crossWindClass(rwy.WindCross)">Cross: {{ rwy.WindCross }}</div>
                                <div :class="headWindClass(rwy.WindHead)">Head: {{ rwy.WindHead }}</div>
                            </div>
                        </span>
                    </span>

                    <ul class="rwy-selection dropdown-menu">
                        <li v-for="(rwy, id, rwyIdx) in airport.runways" :key="rwyIdx" class="dropdown-item" @click="selectRunway(airport.icao, id)">
                            <span style="padding-right: 5px">{{ id }}</span>
                            <span :class="crossWindClass(rwy.WindCross)">{{ rwy.WindCross }}</span>/
                            <span :class="headWindClass(rwy.WindHead)">{{ rwy.WindHead }}</span>
                        </li>
                    </ul>
                </td>
                <td class="wind">{{ ('0' + airport.weather.windDirection).slice(-3) }}/{{airport.weather.windSpeed}}KT</td>
                <td class="qnh">Q{{airport.weather.qnh}}</td>
                <td class="tl"><span class="text-muted">TL</span> {{airport.weather.tl}}</td>
                <td class="ap-disable" @click="setAirportActive(airport.icao, false)">x</td>
            </tr>
        </table>
      <div class="passive-airports"><span class="passive-airport" v-for="(airport, idx) in PassiveAirports" :key="idx" @click="setAirportActive(airport.icao, true)">{{ airport.icao }}</span></div>
      <div style="padding-top: 20px"><span style="padding: 15px; border: solid 1px lightgray; background-color: gray" @click="updateWeather">R</span></div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: 'HelloWorld',
  props: {
    msg: String
  },
    computed: {
        ActiveAirports: function () {
            let result = [];
            for (let idx in this.$store.state.airports.airports) {
                let airport = this.$store.state.airports.airports[idx];
                if (airport.active)
                    result.push(airport)
            }
            return result;
        },
        PassiveAirports: function () {
            let result = [];
            for (let idx in this.$store.state.airports.airports) {
                let airport = this.$store.state.airports.airports[idx];
                if (!airport.active)
                    result.push(airport)
            }
            return result;
        }
    },
    methods: {
      openModal: function () {
          let args = {
              modalView: 'Testi',
              modalArguments: {
                  koe: 1
              },
              modalSize: 'sm'
          }

          this.error = false
          this.$emit('showModal', args);
      },
        selectRunway: function (airport, runway) {

            let params = {
                icao: airport,
                runway: runway
            }

            axios
                .post(process.env.VUE_APP_BACKEND_URL + 'Runways/RunwayActive',  params )
                .then(response => (
                    this.UpdatedInfo(response)
                ));
        },
        setAirportActive: function (airport, active) {

            let params = {
                icao: airport,
                active: active
            }

            axios
                .post(process.env.VUE_APP_BACKEND_URL + 'AirportActive',  params )
                .then(response => (
                    this.UpdatedInfo(response)
                ));
        },
        UpdatedInfo(response) {
          console.log(response)
            this.$store.dispatch('fetchAirports')
        },
        headWindClass: function (value) {
          if (value < 0)
              return 'rwy-wind-head text-danger';

          return 'rwy-wind-head text-success';
        },
        crossWindClass: function (value) {
          if (Math.abs(value) > 15)
              return 'rwy-wind-cross text-danger';

          return 'rwy-wind-cross text-success';
        },
        updateWeather: function () {
          this.$store.dispatch('updateWeather');
        }
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

.airport {
    border: solid 1px gray;
    background-color: lightgray;
}

.icao {
    width: 140px;
    font-weight: bold;
    font-size: 24pt;
    padding: 10px;
}

.rwy {
    width: 200px;
    padding: 10px;
}

.rwy-option:empty { display: none }

.rwy-id {
    font-size: 24pt;
    float: left;
    width: 70px;
    height: 50px;

}

.rwy-components {
    float: left;
    height: 50px;
    margin: 0;
    padding: 0;
    padding-top: 7px;
    padding-left: 5px;
    font-size: 8pt;
    text-align: left;
}

.passive-airport {
     border: solid 1px gray;
     background-color: lightgray;
     padding: 5px;
 }

.rwy-selection {
    background-color: #efefef;
}

.wind {
    font-size: 20pt;
}
.qnh {
    font-size: 20pt;
}
.tl {
    font-size: 20pt;
}
</style>
