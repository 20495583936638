import axios from "axios";

export const state = {
    airports: null
}

export const actions = {
    checkFlightStatusTimer: function (context) {
        setTimeout(() => {
            context.dispatch('fetchAirports')
        },10000)
    },
    fetchAirports: function (context) {
        let url = process.env.VUE_APP_BACKEND_URL + '/getAirports'
        console.log(url)
        let data = {
        }
        axios
            .get(url, data)
            .then(response => (
                context.dispatch('handleFetchAirportsResponse', response)
            ))

    },
    handleFetchAirportsResponse: function (context, response) {
        if (response.data !== undefined) {
            context.commit('setAirportData', response.data.result)
        }
    },
    updateWeather: function (context) {
        let url = process.env.VUE_APP_BACKEND_URL + '/UpdateWeather'
        let data = {
        }
        axios
            .get(url, data)
            .then(response => (
                context.dispatch('handleUpdateWeatherResponse', response)
            ))

    },
    handleUpdateWeatherResponse: function (context) {
        context.dispatch('fetchAirports')
    }

}

export const mutations = {
    setAirportData (state, data) {
        state.airports = data
    },
}

export const getters = {
}

export default {
    state,
    actions,
    mutations,
    getters,
    modules: {
        // investor
    }
}
