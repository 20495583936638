<template>
    <div v-if="isVisible">
        <div class="modal fade show modal-active" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true" :id="componentName">
            <div :class="modalClass">
                <div class="modal-content">
                    <keep-alive>
                        <component :is="modalComponentFile" :args="args" @closeModal="closeModal"></component>
                    </keep-alive>
                </div>
            </div>
        </div>
        <div class="modal-backdrop fade show"></div>
    </div>
</template>

<script>
import { defineAsyncComponent } from 'vue'

export default {
    name: "Modal",
    props: {
        modalComponent: {
            type: String,
            required: false
        },
        modalArguments: {
            type: Object,
            required: false
        },
        modalSizeClass: {
            type: String,
            required: false
        }
    },
    computed: {
        modalComponentFile() {
            let component = this.componentName
            return defineAsyncComponent(() => import("./" + component + ".vue"));
        },
        modalClass: function() {
            let modalSize = ''
            if (this.sizeClass !== null) {
                modalSize = this.sizeClass
            }
            // console.log(modalSize)
            return 'modal-dialog ' + modalSize
        }
    },
    methods: {
        closeModal: function () {
            this.isVisible=false
        },
        openModal: function (modalParameters) {
            console.log('mopa')
            console.log(modalParameters)
            this.componentName = modalParameters.modalView
            this.args = modalParameters.modalArguments
            this.sizeClass = modalParameters.modalSize
            this.isVisible=true
        },
        showModal: function () {
            this.args = this.modalArguments
            this.componentName = this.modalComponent
            this.sizeClass = this.modalSizeClass
            this.isVisible=true
        }
    },
    data: function () {
        return {
            isVisible: false,
            componentName: null,
            sizeClass: null,
            args: null
        }
    },
    mounted() {
        this.args = this.modalArguments
        this.sizeClass = this.modalSizeClass
        this.componentName = this.modalComponent
    },
    watch: {
        modalArguments: function (){

        }
    }
}
</script>

<style scoped>
.modal-active{
    display:block;
}
</style>
