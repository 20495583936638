<template>
  <HelloWorld @showModal="openModal" msg="Welcome to Your Vue.js App"/>
    <Modal ref="ModalComponent" :modal-component="modalView" :modalArguments="modalArguments" :modal-size-class="modalSizeClass"></Modal>
</template>

<script>
import HelloWorld from './components/HelloWorld.vue'
import axios from "axios";
import Modal from "@/components/Modals/Modal";

export default {
  name: 'App',
  components: {
        Modal,
        HelloWorld
  },

    data: function () {
        return {
            updateTimer: null,
            modalView: null,
            modalArguments: null,
            modalSizeClass: null,
        }
    },
    methods: {
        openModal: function (modalParameters) {
            //let modalView = modalParameters.modalView
            //let modalArguments = modalParameters.modalArguments
            //let modalSize = modalParameters.modalSize
            //this.showModal(modalView, modalArguments, modalSize)
            this.$refs.ModalComponent.openModal(modalParameters);
        },
        showModal: function (modalView, modalArguments, modalSize = '') {
            this.modalView = modalView
            this.modalArguments = modalArguments
            this.modalSizeClass = modalSize

            this.$refs.ModalComponent.showModal();
        },
        FetchData: function () {
            axios
                .get('https://metar.vatsim.net/metar.php?id=EF')
                .then(response => {
                  this.HandleData(response)
                })
        },
        HandleData: function (response) {
            console.log(response)
        }
    },
    mounted () {
      //this.updateTimer = setInterval(this.FetchData(), 10000);
        this.$store.dispatch('fetchAirports')
        this.$store.dispatch('checkFlightStatusTimer')
    }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}
</style>
